import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import * as hero from '@css/modules/page-hero.module.scss';
import * as rsrc from '@css/modules/resource-header.module.scss';

export default function PageHero({ data, bigSubTitle = false }) {
  const { header, subTitle, subTitleLineTwo, image } = data;
  return (
    <Row
      noGutters
      className={`${subTitle ? hero.full : hero.slim} ${hero.parent}`}
    >
      <Col
        className={`bg-secondary ${hero.leftContainer}`}
        xs={3}
        sm={5}
        md={6}
      >
        <div
          className={`${hero.contentParent} ${
            bigSubTitle ? hero.bigSubTitle : ''
          }`}
        >
          <h1 className={`${hero.header}`}>{header}</h1>
          {subTitle && <p className={`mb-1 ${hero.copy}`}>{subTitle}</p>}
          {subTitleLineTwo && (
            <p className={`${hero.subTitleLineTwo}`}>{subTitleLineTwo}</p>
          )}
        </div>
      </Col>
      {image ? (
        <Col className={hero.customImage} xs={9} sm={7} md={6}>
          <BackgroundImage
            fixed={image.fixed}
            alt={image.title}
            style={{ clipPath: 'circle(50%)', width: '100%' , height: '256px'}}
          />
        </Col>
      ) : (
        <Col className={hero.rightContainer} xs={9} sm={7} md={6} />
      )}
    </Row>
  );
}

export function ResourceHero({ data }) {
  const { title, provider, tags, tileImage, heroImage, nodeLocale } = data;
  return (
    <Row noGutters className={rsrc.resource}>
      <Col
        className={`${rsrc.content}`}
        style={{ backgroundColor: provider.branding.background || '#5b3892' }}
      >
        <div className={rsrc.imgWrapper}>
          {heroImage || tileImage ? (
            <BackgroundImage
              fixed={heroImage ? heroImage.fixed : tileImage.fixed}
              alt={heroImage ? heroImage.title : tileImage.title}
            />
          ) : null}
        </div>
        <h1 className={rsrc.pageTitle}>{title}</h1>
        <div>
          <p className={rsrc.offeredBy}>
            {nodeLocale.includes('fr') ? 'Offertes par' : 'Provided by'}
          </p>
          <a
            href={provider?.url}
            title={provider?.name}
            target="_blank"
            rel="noopener noreferrer"
          >
            <GatsbyImage
              image={provider.logo.gatsbyImageData}
              sizes={provider.logo.sizes}
              className={rsrc.providerImage}
              alt={provider.logo.title}
            />
          </a>
        </div>
        <div className={rsrc.tagWrapper}>
          <ul>
            {tags?.map((tag) => (
              <li className={rsrc.tag} key={tag}>
                {tag}
              </li>
            ))}
          </ul>
        </div>
      </Col>
    </Row>
  );
}

export function ServiceHero({ data }) {
  const { header, subTitle, subTitleLineTwo } = data;
  return (
    <div className={`${hero.serviceBackground} bg-secondary py-4 py-lg-5`}>
      <Container>
        <Row noGutters>
          <Col className={`${hero.leftContainer} py-lg-4`} xs={7} md={6}>
            <div className={hero.contentParent}>
              <h1 className={hero.header}>{header}</h1>
              {subTitle && <p className={`mb-1 ${hero.copy}`}>{subTitle}</p>}
              {subTitleLineTwo && (
                <p className={hero.subTitleLineTwo}>{subTitleLineTwo}</p>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
