// extracted by mini-css-extract-plugin
export const parent = "page-hero-module--parent--i6FgX";
export const leftContainer = "page-hero-module--leftContainer--3y7hL";
export const rightContainer = "page-hero-module--rightContainer--OMJMb";
export const customImage = "page-hero-module--customImage--1StQk";
export const slim = "page-hero-module--slim--VOD0k";
export const contentParent = "page-hero-module--contentParent--2TEsK";
export const header = "page-hero-module--header--3RHu-";
export const full = "page-hero-module--full--14Z9u";
export const copy = "page-hero-module--copy--2Djaq";
export const subTitleLineTwo = "page-hero-module--subTitleLineTwo--2AzvG";
export const right = "page-hero-module--right--24g9_";
export const bigSubTitle = "page-hero-module--bigSubTitle--2PrSO";
export const crisisHeader = "page-hero-module--crisisHeader--2cqrk";
export const crisisAction = "page-hero-module--crisisAction--3uAYv";
export const serviceBackground = "page-hero-module--serviceBackground--1Cgsu";