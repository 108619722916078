import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import {
  HeartFill,
  StarFill,
  ShieldFillCheck,
  ChatSquareQuote,
} from 'react-bootstrap-icons';

import PageLayout from '@components/layout/PageLayout';
import PageHero from '@components/layout/PageHero';
import * as measurementStyles from '@css/modules/measurement.module.scss';
import { useMPEvent } from '@util/mixpanel';
import { Card, Row } from 'react-bootstrap';

// @ts-expect-error
export default function Measurement({ data }) {
  const {
    node_locale: nodeLocale,
    title,
    subtitle,
    slideshow1,
    quotesSlideshow,
    ctaTitle,
    ctaText,
    measurementPost,
    createButtonCopy,
    ctaImg,
  } = data.contentfulMeasurementPage;
  const subTitle = subtitle;
  const header = title;
  const icons = {
    StarFill: <StarFill />,
    HeartFill: <HeartFill />,
    ShieldFillCheck: <ShieldFillCheck />,
    ChatSquareQuote: <ChatSquareQuote />,
  };
  const { trackPageLoad } = useMPEvent();

  useEffect(() => {
    trackPageLoad({
      language: nodeLocale.toLowerCase(),
      url: `/${nodeLocale}/measurement`,
      url_name: 'measurement',
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    // @ts-expect-error
    <PageLayout node_locale={nodeLocale} pageTitle={title}>
      <PageHero data={{ header, subTitle }} />

      <div className={measurementStyles.background}>
        <Container className={measurementStyles.measurementContainer}>
          <Row className="justify-content-center">
            <Col md={10}>
              <div>
                {/* @ts-expect-error */}
                {measurementPost.map((post, index) => (
                  <div key={index}>
                    <h4>{post.title}</h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: post.content.childMarkdownRemark.html,
                      }}
                    />
                  </div>
                ))}
              </div>

              {/* @ts-expect-error */}
              {slideshow1.map(({ icon, title: cardTitle, content }, index) => (
                <Card
                  className={`${measurementStyles.mbcInfoCard} mb-4`}
                  key={`mbc-info-card-${index}`}
                >
                  <Card.Header className="bg-secondary text-white d-flex align-items-center">
                    {/* @ts-expect-error */}
                    <div className="mr-2">{icons[icon]}</div>
                    <div>{cardTitle}</div>
                  </Card.Header>
                  <Card.Body
                    dangerouslySetInnerHTML={{ __html: content }}
                    style={{ border: '1px solid #e5e5ff' }}
                    className="rounded-bottom"
                  />
                </Card>
              ))}

              <div className={measurementStyles.measurementCard}>
                <GatsbyImage
                  image={ctaImg.gatsbyImageData}
                  alt={ctaImg.title}
                  className={measurementStyles.contentImageWrapper}
                />

                <div className={measurementStyles.createAccount}>
                  <h4>{ctaTitle}</h4>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: ctaText.childMarkdownRemark.html,
                    }}
                  />
                  <a
                    href={`${
                      process.env.GATSBY_GREENSPACE_URL
                    }/self_guided/signup/?lang=${nodeLocale.toLowerCase()}`}
                    className={`${measurementStyles.ctaButton} btn btn-primary`}
                  >
                    {createButtonCopy}
                  </a>
                </div>
              </div>

              <hr className="my-5" />

              {/* @ts-expect-error */}
              {quotesSlideshow.map(({ quote, author }, quoteIndex) => (
                <Card
                  className={`${measurementStyles.mbcQuoteCard} mb-4`}
                  key={`mbc-quote-${quoteIndex}`}
                >
                  <Card.Body>
                    <Row>
                      <Col
                        xs={12}
                        md="auto"
                        className="d-flex mb-1"
                        style={{ fontSize: '3rem' }}
                      >
                        {icons.ChatSquareQuote}
                      </Col>
                      <Col>
                        <blockquote className="m-0">
                          <p
                            className={`${measurementStyles.mbcQuoteText} mb-3`}
                          >
                            {quote}
                          </p>
                          <footer
                            dangerouslySetInnerHTML={{ __html: author }}
                          />
                        </blockquote>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              ))}
            </Col>
          </Row>
        </Container>
      </div>
    </PageLayout>
  );
}

export const query = graphql`
  query($node_locale: String!) {
    contentfulMeasurementPage(node_locale: { eq: $node_locale }) {
      id
      title
      subtitle
      slideshow1 {
        id
        icon
        title
        content
      }
      quotesSlideshow {
        id
        author
        quote
      }
      ctaTitle
      ctaText {
        childMarkdownRemark {
          html
        }
      }
      measurementPost {
        id
        title
        content {
          childMarkdownRemark {
            html
          }
        }
      }
      createButtonCopy
      ctaImg {
        gatsbyImageData(
          quality: 100
          height: 500
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
      node_locale
    }
  }
`;
